export const PLATFORM_NAME =
  'iCapital Structured Investments and Annuities Platform';
export const COMPANY_NAME = 'iCapital';
export const NETWORK_COMPANY_NAME = 'iCapital Network';
export const LLC_COMPANY_NAME = 'iCapital Markets LLC';
export const INC_NAME = 'Institutional Capital Network, Inc.';
export const INC_NAME_SHORT = `${COMPANY_NAME}, Inc.`;
export const ANNUITIES_SERVICE_NAME =
  'iCapital Annuities and Insurance Services';
export const LLC_ANNUITIES_SERVICE_NAME = `${ANNUITIES_SERVICE_NAME} LLC`;
export const PRIVACY_POLICY_URL = 'https://icapital.com/privacy-policy/';
export const TERMS_OF_SERVICE_URL = 'https://icapital.com/terms-of-service/';
export const SUPPORT_EMAIL_ADDRESS = 'hello@icapital.com';
export const PHONE_NUMBER = '+1-877-317-4666';
export const COMPANY_URL = 'https://icapital.com/';
