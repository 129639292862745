/**
 * Supernova/MUI theme overrides for SIMON
 * @type {import('@icapitalnetwork/supernova-core/Provider').ThemeProviderProps['theme']}
 * */
export default {
  palette: {
    // Use SIMON's light gray background
    background: {
      default: null,
    },
    // Use SIMON's blue color as a primary color scheme
    primary: {
      main: '#2f66a5',
    },
    chart: {
      aqua: {
        aqua80: '#2c3e70',
        aqua70: '#2f66a5',
        aqua60: '#3d7bc4',
        aqua40: '#4a8fe2',
        aqua30: '#afcef2',
        aqua20: '#d4e4f8',
      },
    },
  },
  // Not sure how exactly this fontSize property works, because it doesn't work as expected when using 14.
  // Using 12.25 value renders exactly 14px on body tag, matching existing SIMON typography 1:1
  typography: {
    fontSize: 12.25,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
  // Add custom variant "inverted" - solid white button with blue text color
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#2f66a5',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderRightWidth: '2px',
        },
      },
    },
    MuiCircularProgress: {
      variants: [
        {
          props: { variant: 'indeterminate' },
          style: {
            color: 'inherit',
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'inverted' },
          style: {
            color: '#2f66a5',
            background: '#ffffff',
            '&:hover': {
              background: '#ffffff',
            },
            '&:active': {
              color: '#2c3e70',
            },
          },
        },
        {
          props: { variant: 'inverted-text' },
          style: {
            color: '#ffffff',
            borderColor: '#ffffff',
            '&:hover': {
              background: 'rgba(255,255,255,0.06)',
            },
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          //replace the next 5 lines by:
          //import { TooltipOverrides } from '@icapitalnetwork/supernova-core/Tooltip/MuiTooltipOverrides';
          //when SN accepts style overrides
          ...theme.typography.smallParagraph,
          backgroundColor: theme.palette.neutral?.n90,
          boxShadow:
            '0px 2px 5px rgba(0, 0, 0, 0.05), 0px 2px 7px rgba(0, 0, 0, 0.1)',
          color: theme.palette.neutral?.n0,
          border: `1px solid ${theme.palette.neutral?.n5}`,

          textAlign: 'center',
          padding: '6px 12px',
          fontSize: 14,
          lineHeight: 1.5,
        }),
      },
    },
  },
};
